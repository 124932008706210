<template>
    <div class="site-login w-100 p-xxl-5 p-xl-5 p-lg-3 p-md-3 p-sm-2 p-2">
        <h2 class="text-purple mb-3">Welcome to Login</h2>
        <login-form></login-form>
    </div>
</template>

<script>
    import LoginForm from "./LoginForm.vue";

    export default {
        name: "guest-login",
        components: {
            LoginForm,
        },
    };
</script>
